.btn-blue{
    height: 3.1rem;
    width: 6.5rem;
    padding: 1rem;
    border: none;
    border-radius: 0.6rem;
    background: var(--blue);
    color: var(--white);
    font-weight: 600;
    font-size: 0.97rem;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
    letter-spacing: 0.04rem;
    position: absolute;
    right: 1.55rem;
}
.btn-blue:hover{
    opacity: 0.4;
}
.form-update{
    display: flex;
    flex-direction: column;
    align-items:flex-end;
}
.btn-update{
    position: initial;
    margin-top: 1rem;
}