.container,
.author-details,
.comment,
.btns,
.author-username,
.comment-container,
.reply-container,
.reply-to-reply{
    display: grid;
}
.container{
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 1rem;
    background: var(--white);
    width: 100%;
    min-height: 10.5rem;
    border-radius: 0.5rem;
    padding: 1.1rem;
    margin-bottom: 1rem;
    position: relative;
}
.reply-separator{
    background: var(--gray);
    width: 0.135rem;
    margin: 0;
}
.reply-container{
    grid-template-columns: 1rem auto;
}

.reply-to-reply{
    grid-template-columns: 1rem 1rem auto;
}
.comment-vote,
.comment-author{
    display: flex;
}
.comment-vote{
    grid-row-start: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .7rem;
    background: var(--gray-light);
    border-radius: 0.7rem;
    height: 2.5rem;
    width: 4.5rem;
}
.number-vote{
    color: var(--blue);
    font-weight: 500;
}
.btn-vote:hover .btn-icon{
    --icon: var(--blue) ;
}
.btns{
    grid-auto-flow: column;
    gap: .5rem;
    position: absolute;
    bottom: 1.7rem;
    right: 1.1rem;
}
.btn{
    border: none;
    background: transparent;
    cursor: pointer;
}
.btn-reply-blue span,
.btn-reply-red span{
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--red);
    margin-inline-start: 0.5rem;
}
.btn-reply-blue span{
    color: var(--blue);
}
.btn-reply-red span{
    color: var(--red);
}
.btn-reply-blue:hover,
.btn-reply-red:hover{
    opacity: 0.4;
}
.comment-author{
    justify-content: space-between;
}
.author-details{
    grid-auto-flow: column;
    align-items: center;
    gap: 1rem;
}
.img-author{
    width: 2rem;
}
.name-author{
    font-weight: 500;
    color: var(--blue-dark);
}
.author-username{
    grid-auto-flow: column;
    gap: 0.5rem;
}
.tag-author{
    background: var(--blue);
    display: flex;
    align-items: center;
    color: var(--white);
    border-radius: 0.15rem;
    padding: 0.1rem .42rem 0.185rem .42rem;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.04rem;
}
.comment-description,
.comment-age{
    color:var(--blue-gray);
}
.comment{
    gap: 0.9rem;
}
.comment-description{
    line-height: 1.5rem;
}
.user-replying-to{
    color: var(--blue);
    font-weight: 500;
}
@media only screen and (min-width:23rem){
    .btn-reply-blue span,
    .btn-reply-red span{
        font-size: 1rem;
    }
    .btns{
        gap: 1rem;
    }
    .comment-vote{
        width: 6rem;
    }
}
@media only screen and (min-width:51rem){
    .container{
        grid-template-columns: 2.5rem auto;
        gap: 1.5rem;
        grid-template-rows: auto ;
        min-height: 10.5rem;
        padding: 1.5rem;
        margin-bottom: 1.25rem;
    } 
    .comment-vote{
        flex-direction: column;
        height: 6.2rem;
        width: 2.5rem;
        grid-row-start: 1;
    }
    .btns{
        gap: 1.4rem;
        position: initial;
    }
    .reply-container{
        grid-template-columns: 5.3rem auto;
    }
    .reply-to-reply{
        grid-template-columns: 5.3rem 5.3rem auto;
    }
    .reply-separator{
        margin: 0 auto;
    }
}