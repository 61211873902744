@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
:root{
    --blue: hsl(238, 40%, 52%);
    --red: hsl(358, 79%, 66%);
    --blue-light: hsl(239, 57%, 85%);
    --red-light: hsl(357, 100%, 86%);
    --blue-dark: hsl(212, 24%, 26%);
    --blue-gray: hsl(211, 10%, 45%);
    --blue-gray-transparent: rgba(0, 0, 0, 0.5);
    --gray: hsl(223, 19%, 93%);
    --gray-light: hsl(228, 33%, 97%);
    --white: hsl(0, 0%, 100%);
    --icon:var(--blue-light);
}
html{
    box-sizing: border-box;
}
*,*::after,*::before{
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    transition: .3s ease-in-out;
}
body{
    background: var(--gray-light);
    font-family: 'Rubik', sans-serif;
    font-size: 0.8rem;
}
.title-screen-readers-only{
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}
@media only screen and (min-width:23rem){
    body{
        font-size: 1rem;
    }
}