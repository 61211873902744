.modal{
    position: fixed;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--blue-gray-transparent);
}
.modal-alert{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--white);
    width: 91%;
    max-width: 25rem;
    height: 15.7rem;
    border-radius: .5rem;
    padding: 2rem 1.95rem;
}
.modal-title{
    color: var(--blue-dark);
    font-weight: 500;
}
.modal-description{
    color: var(--blue-gray);
    font-weight: 400;
    line-height: 1.5rem;
}
.modal-btns{
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
}
.btn-modal{
    border: none;
    cursor: pointer;
    padding: .9rem;
    font-family: 'Rubik', sans-serif;
    color: var(--white);
    font-size: 1rem;
    font-weight: 400;
    border-radius: .5rem;
}
.btn-gray{
    background: var(--blue-gray);
}
.btn-red{
    background: var(--red);
}
