.new-comment-container{
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 1rem;
    background: var(--white);
    width: 100%;
    min-height: 9.2rem;
    border-radius: 0.5rem;
    padding: 1.55rem;
    margin-bottom: 1.25rem;
    position: relative;
}
.new-comment-pic{
    grid-row-start: 2;
    grid-row-end: 3;
}
.new-comment-description{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    font-family: 'Rubik', sans-serif;
    border: 0.1rem solid var(--gray);
    color: var(--blue-dark);
    border-radius: .5rem;
    padding: 0.7rem 1.4rem;
    width: 100%;
    line-height: 1.5rem;
    font-size: 1rem;
    font-weight:400;
    resize: none;
}
.btn-reply{
    grid-row-start: 2;
    grid-row-end: 3;
}
.new-comment-description:focus{
    outline: 0.1rem solid var(--blue-dark);
}
.nc-img-author{
    margin-top:0.2rem;
    width: 100%;
    max-width: 2.5rem;
}
@media only screen and (min-width:50rem){
    .new-comment-container{
        grid-template-columns: 2.5rem 31.7rem 6.5rem;
        grid-template-rows: auto;
    }
    .new-reply-form{
        grid-template-columns: 2.5rem 25rem 6.5rem;
        grid-template-rows: auto;
    }
    .new-reply-form-to-reply{
        grid-template-columns: 2.5rem 20rem 6.5rem;
        grid-template-rows: auto;
    }
    .new-comment-pic{
        grid-row-start: 1;
        grid-row-end: 2;
    }
    .new-comment-description{
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .btn-reply{
        grid-row-start: 1;
        grid-row-end: 2;
    }
}